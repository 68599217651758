.container{
    padding: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.container>:nth-child(1){
    position: absolute;
    width: 8rem;
    left: 30%;
    top: -3rem;
}

.products{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.3rem;
}

.menu>li:hover{
    color: var(--pink);
    cursor: pointer;
}

.list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 25rem;
    overflow: scroll;
    grid-gap: 1.8rem;
    justify-content: space-between;
}

.product{
    width: 10rem;
    height: 8rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
}

.product>img{
    top: 3rem;
    width: 6rem;
    height: 11rem;
    transform: rotate(-20deg);
}

@media screen and (max-width: 850px) {
    .container{
        gap: 1rem;
    }

    .container>:nth-child(1){
        display: none;
    }
    .products{
        grid-template-columns: none;
        gap: 1rem;
    }
    .menu{
        flex-direction: row;
    }
}
@media screen and (max-width: 600px) {
    .container{
        gap: 1rem;
    }

    .container>:nth-child(1){
        display: none;
    }
    .container>h1{
        text-align: center;
    }
    .products{
        grid-template-columns: none;
        gap: 1rem;
    }
    .menu{
        flex-direction: row;
        gap: 1rem;
        padding-left: 0;
        margin-left: -2.5rem;
    }
    .product{
        width: 18rem;
        align-items: center;
        grid-column: 1fr 1fr;
    }
}
